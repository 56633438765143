#modal_header{
    display: flex;
    flex-direction: column;
    padding: 16px;
    ion-button{
        margin-left: auto;
        --padding-start: 4px;
        --padding-end: 4px;

        ion-icon{
            font-size: 14px;
            color: var(--ion-color-dark);
        }
    }
    align-items: center;
    ion-icon{
        font-size: 24px;
        color: var(--ion-color-primary);
    }
    h3{
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        color: var(--ion-color-dark);
    }
}