#login_screen {
  .login_screen_background {
    background: url("./login-background.jpg");
    background-size: cover;

    .login_screen_box {
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      background: var(--ion-color-light);

      .login_screen_content_skip {
        margin-left: auto;
      }

      .login_screen_content {
        hr {
          background: var(--ion-color-light-shade);
          width: 100%;
          height: 0.2px;
          margin: 20px 0px;
        }
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        // text-align: center;
        ion-button {
          text-transform: none;
          --box-shadow: none;
          width: 100%;
        }

        .login_screen_action {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: var(--ion-color-dark);
            font-size: 24px;
            font-weight: 600;
            margin: 32px 0 8px 0;
            text-align: center;
          }
          span:not(.login_screen_privacy, .version) {
            margin: 16px 0;
            font-size: 14px;
          }
          ion-button {
            height: 52px;
          }

          hr {
            margin: 10px
          }
        }
      }
      span.login_screen_privacy {
        margin-top: 0;
        padding: 10px;
        color: #d8d8d8;
        line-height: 16px;
        font-size: 12px;
      }

      span.version {
        color: var(--ion-color-primary);
        text-align: center;
        font-size: 12px;
        margin: 0;
        padding: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .header-login {
    display: none;
  }
  .login_screen_box {
    margin-left: auto;
    max-width: 411px;
  }
}

@media only screen and (max-width: 768px) {
  .header-login {
    border: none;
    box-shadow: none;
    background-color: var(--ion-color-light);
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    img {
      width: 100%;
      background-repeat: no-repeat !important;
      background-size: contain;
      object-fit: fill;
      max-height: 300px;
    }
  }
  #login_screen {
    height: 100dvh;
    width: 100vw;

    .ion-content {
      height: 100dvh;
      display: flex;
    }
    .login_screen_background {
      background: none;
      background-size: cover;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .login_screen_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: var(--ion-color-light);

        .login_screen_content_skip {
          margin-left: auto;
        }

        .login_screen_content {
          hr {
            background: var(--ion-color-light-shade);
            width: 100%;
            height: 0.2px;
            margin: 20px 0px;
          }
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          height: 100dvh;
          // text-align: center;
          ion-button {
            text-transform: none;
            --box-shadow: none;
            width: 100%;
          }

          .login_screen_action {
            margin-top: 250px;
            display: flex;
            flex-direction: column;
            height: 100dvh;
            justify-content: flex-start;
            z-index: 1000;
            p {
              color: var(--ion-color-dark);
              font-size: 24px;
              font-weight: 600;
              margin: 32px 0 8px 0;
              text-align: center;
            }
            span:not(.login_screen_privacy, .version) {
              margin: 14px 0;
              font-size: 14px;
            }
            ion-button {
              height: 52px;
            }

            .link {
              display: flex;
              flex-direction: column;
              gap: 0px;
              ion-button {
                height: 35px;
              }
            }

          }
        }
        span.login_screen_privacy {
          margin-top: 0px;
          padding: 10px;
          color: #d8d8d8;
          line-height: 16px;
          font-size: 12px;
        }
        span.version {
          color: var(--ion-color-primary);
          text-align: center;
          font-size: 12px;
          margin: 0;
          padding: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header-login {
    display: none;
  }
  #login_screen {
    height: 100dvh;
    width: 100vw;

    .ion-content {
      height: 100dvh;
      display: flex;
    }
    .login_screen_background {
      background: none;
      background-size: cover;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .login_screen_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: var(--ion-color-light);

        .login_screen_content_skip {
          margin-left: auto;
        }

        .login_screen_content {
          hr {
            background: var(--ion-color-light-shade);
            width: 100%;
            height: 0.2px;
            margin: 20px 0px;
          }
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100dvh;
          // text-align: center;
          ion-button {
            text-transform: none;
            --box-shadow: none;
            width: 100%;
          }

          .login_screen_action {
            display: flex;
            flex-direction: column;
            height: 100dvh;
            justify-content: center;
            z-index: 1000;
            p {
              color: var(--ion-color-dark);
              font-size: 24px;
              font-weight: 600;
              margin: 32px 0 8px 0;
              text-align: center;
            }
            span:not(.login_screen_privacy, .version) {
              margin: 14px 0;
              font-size: 14px;
            }
            ion-button {
              height: 52px;
            }
            .link {
              display: flex;
              flex-direction: column;
              gap: 0px;
              ion-button {
                height: 35px;
              }
            }
          }
        }
        span.login_screen_privacy {
          margin-top: 0px;
          padding: 10px;
          color: #d8d8d8;
          line-height: 16px;
          font-size: 12px;
        }
        span.version {
          color: var(--ion-color-primary);
          text-align: center;
          font-size: 12px;
          margin: 0;
          padding: 8px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .header-login {
    border: none;
    box-shadow: none;
    background-color: var(--ion-color-light);
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    img {
      width: 100%;
      background-repeat: no-repeat !important;
      background-size: contain;
      object-fit: fill;
      max-height: 300px;
    }
  }
  #login_screen {
    height: 100dvh;
    width: 100vw;

    .ion-content {
      height: 100dvh;
      display: flex;
    }
    .login_screen_background {
      background: none;
      background-size: cover;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .login_screen_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: var(--ion-color-light);

        .login_screen_content_skip {
          margin-left: auto;
        }

        .login_screen_content {
          hr {
            background: var(--ion-color-light-shade);
            width: 100%;
            height: 0.2px;
            margin: 20px 0px;
          }
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100dvh;
          // text-align: center;
          ion-button {
            text-transform: none;
            --box-shadow: none;
            width: 100%;
          }

          .login_screen_action {
            margin-top: 300px;
            display: flex;
            flex-direction: column;
            height: 100dvh;
            justify-content: center;
            z-index: 1000;
            p {
              color: var(--ion-color-dark);
              font-size: 24px;
              font-weight: 600;
              margin: 32px 0 8px 0;
              text-align: center;
            }
            span:not(.login_screen_privacy, .version) {
              margin: 14px 0;
              font-size: 14px;
            }
            ion-button {
              height: 52px;
            }
            .link {
              display: flex;
              flex-direction: column;
              gap: 0px;
              ion-button {
                height: 52px;
              }
            }
          }
        }
        span.login_screen_privacy {
          margin-top: 0px;
          padding: 10px;
          color: #d8d8d8;
          line-height: 16px;
          font-size: 12px;
        }
        span.version {
          color: var(--ion-color-primary);
          text-align: center;
          font-size: 12px;
          margin: 0;
          padding: 8px;
        }
      }
    }
  }
}
