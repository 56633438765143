#error_social_modal {
  .container-error-social{
    padding: 30px;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 8px 0px;
      text-align: center;
    }

    .section-error {
      margin: 0px 0px 40px 0px;
      ion-icon {
        font-size: 82px;
        display: flex;
        margin: auto auto;
      }
    }

    .section-action {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0px 0px 20px 0px;
      .buttons {
        width: 100%;
        margin: 5px 0px 0px 0px;
      }

    }

  
    ion-button {
      margin: 10px  0px;
      text-transform: none;
      --box-shadow: none;
      width: 100%;
      height: 52px;

  }
  }
}
