.steps-tabs {
  counter-reset: step;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    list-style: none;
    display: inline-block;
    width: fit-content;
    position: relative;
    text-align: center;
    cursor: pointer;
    padding: 0px 5px;
    max-height: 64px;
    max-width: fit-content;
    span {
      // line-height: 1rem;
      font-size: 9px;
      color: #808080;
      text-wrap: balance;
    }
    @media (min-width: 768px) {
      padding: 0px 15px;
    }

    &:before {
      content: counter(step);
      counter-increment: step;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;
      line-height: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto 10px auto;
      background-color: #e0e0e0;
      border: 1px solid #e0e0e0;

      font-size: 12px;
      color: #bdbdbd;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #e0e0e0;
      top: 15px;
      left: -50%;
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      color: #bdbdbd;
    }

    &.selected:before {
      border: 1px solid var(--ion-color-primary);
      color: var(--ion-color-primary);
    }

    &.active:before {
      background-color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      color: #fff;
    }
  }
}
