.section-facebook {
  width: 100%;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .facebook-card {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  hr {
    background: var(--ion-color-light-shade);
    width: 100%;
    height: 0.2px;
    margin: 30px 0px;
  }
  p {
    margin: 10px 0px;
    font-size: 14px;
    text-align: center;
  }
  .enter-here {
    margin: 0px 0px 20px 0px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
  ion-button {
    text-transform: none;
    --box-shadow: none;
    width: 100%;
    height: 56px;
  }
}
