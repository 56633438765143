#claro_club_coupon {
  cursor: pointer;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  margin: 8px;
  background: var(--ion-color-light);
  position: relative;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.01);
  }
  ion-img::part(image),
  img, .claro_club_coupon_image {
    width: 100%;
    min-height: 160px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
  }

  
  .claro_club_coupon_content {
    .claro_club_coupon_discount {
      font-size: 12px;
      color: var(--ion-color-primary);
      &.dark {
        color: var(--ion-color-dark);
      }
      font-weight: 600;
    }
    padding: 12px;
    h3 {
      margin: 0 0 8px 0;
      font-size: 16px;
      font-weight: 600;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .claro_club_coupon_time {
      display: flex;
      align-items: center;
      border-top: 1px solid var(--ion-color-medium);
      // margin-bottom: 32px;
      padding: 8px 0 0 0;
      p {
        margin: 0 0 0 8px;
        font-size: 11px;
      }
    }
    ion-button {
      height: 52px;
      --box-shadow: none;
      text-transform: none;
      font-size: 16px;
    }
  }
  .button-interested {
    z-index: 1;
    input {
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    left: 15px;
    top: 15px;
    height: 25px;
    width: 25px;
    background: rgba(34, 36, 40, 0.7);
    border-radius: 50%;
    position: absolute;
    --box-shadow: none;

    .star-regular {
      position: absolute;
      animation: keyframes-star 0.5s;
    }

    .star-solid {
      position: absolute;
      animation: keyframes-star 0.5s;
      display: none;
    }

    ion-icon {
      font-size: 12px;
      animation: keyframes-star 0.5s;
      color: #fff;
    }
     input:checked ~ .star-regular {
      display: none;
    }
    
     input:checked ~ .star-solid {
      display: block;
    }
    .ripple {
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }
  }
}
