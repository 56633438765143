.loading_spinner {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-spinner {
        --color: var(--ion-color-primary);
        transform: scale(3);
    }
}