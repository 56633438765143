.input-select-container {
  position: relative;
  width: 100%;
}
.input-select {
  --padding-start: 0;
  --inner-padding-end: 0;
  z-index: 0;
  width: 100%;
  margin: 0 0 8px 0;
  font-size: 14px;
  ion-label {
    font-size: 16px !important;
    margin-bottom: 12px !important;
  }
  &.item-has-focus {
    --highlight-color-focused: var(--ion-color-medium);
    ion-label {
      color: var(--ion-color-dark) !important;
      font-weight: 500;
    }
    ion-input,
    ion-textarea {
      --background: var(--ion-color-medium);
    }
  }
  ion-input,
  ion-textarea {
    margin-top: 0px;
    --placeholder-color: var(--ion-color-dark);
    --padding-start: 8px !important;
    --padding-end: 8px;
    --background: var(--ion-color-medium);
    min-height: 52px;
    border-radius: 8px;
  }
  span {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: calc(100% - 92%);
    background: transparent;

    ion-icon {
      font-size: 20px;
      color: #757575;
    }
    margin-right: 8px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: var(--item-hover);
    }
  }
  .input-wrapper, .textarea-wrapper   {
    border-radius: 8px;
  }

  &.active .input-wrapper {
    border: 1px solid var(--ion-color-primary);
  }
}

.input-select-items {
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
  background: var(--ion-color-light);
  width: 100%;
  max-width: 100%;
  max-height: 350px;
  // overflow-y: auto;
  .item {
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    &.space-bottom {
      margin-bottom: 4px;
    }
    &.selected {
      background: var(--ion-color-medium);
      border-radius: 6px;
    }
    .title {
      font-size: 14px !important;
      padding: 4px 4px;
      margin: 0 !important;
      display: flex;
      width: 100%;
    }
    &:hover {
      border-radius: 6px;
      background: var(--ion-color-medium);
      cursor: pointer;
    }
  }
}
