.benefit-card {
  border-radius: 20px;
  width: 100%;
  height: 200px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 300px;
  .type-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    min-height: fit-content;

    .section-title {
      display: flex;
      align-items: center;
      gap: 20px;
      .text {
        display: flex;
        flex-direction: column;
        gap: 5px;
        h3, p {
          margin: 0px;
        }
        h3 {
          font-size: 16px;
          line-height: 1rem;
        }
        p {
          font-size: 12px;
        }
      }
      .icon {
        background: var(--ion-color-medium);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon {
          font-size: 52px;
        }
      }
    }
    .stage {
      background-color: var(--ion-color-primary);
      height: fit-content;
      width: fit-content;
      min-width: 50px;
      border-radius: 5px;
      padding: 5px;
      color: #fff;
      font-size: 10px;
      margin: 0px;
      text-transform: capitalize;
    }
  }
}
