#country_modal{
 .country_modal_list{
     ion-item{
         --inner-padding-top: 8px;
         --inner-padding-bottom: 8px;
         &.selected {
             --background: var(--ion-color-medium);
         }
         ion-label{
             margin-left: 12px;
         }
     }
 }   
}