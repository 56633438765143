#toggle_notification{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ion-icon{
            font-size: 32px;
            color: var(--ion-color-dark);
        }
        span{
            margin-top: 12px;
            color: var(--ion-color-dark);
        }
    }
}