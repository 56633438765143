.phone_method{
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 16px;
    // height: 600px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    .phone-icon{
        font-size: 82px;
        display: flex;
        margin: 48px auto;
    }
    .phone_method_country{
        cursor: pointer;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        background: var(--ion-color-medium);
        border-radius: 8px;
        margin-bottom: 28px;
        ion-icon{
            font-size: 32px;
            margin: auto 0px;
        }
        p{margin: 0 0 0 12px;}
    }

}