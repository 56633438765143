#coupon_screen_skeleton {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    .coupon_screen_skeleton_image {
        width: 100%;
        height:90%;
        margin: 0;
    }

    .coupon_header_screen_skeleton {
        width: 100%;
        height: 100%;
        padding: 10px;

        .coupon_header_screen_title {
            width: 50%;
            height: 9%;
        }

        .coupon_header_screen_description {
            margin: 10px auto auto auto;
            width: 100%;
            height: 15%;
        }
        .coupon_content_screen_discount {
            margin: 10px auto auto auto;
            width: 100%;
            height: 40%;
        }
        .coupon_content_screen_list{
            margin: 10px auto auto auto;
            width: 100%;
            height: 90%;
        }
    }

}