$grey-light: hsl(200, 10%, 92%);
$grey: hsl(200, 10%, 85%);
$grey-dark: hsl(200, 10%, 70%);
$shadow: hsla(200, 20%, 20%, 0.25);
$divider: $grey-light;
$border: $grey-dark;
$cutout-size: 1rem;

.ticket {

    *,
    *::after {
      box-sizing: border-box;
      margin: 0;
    }
    
    display: grid;
    grid-template-rows: auto 1fr auto;
    // max-width: 24rem;
    margin: 20px 12px 12px 12px;
    &__header,
    &__body,
    &__footer {
      padding: 1.25rem;
      background-color: var(--ion-color-light);
      border: 1px solid $border;
      box-shadow: 0 2px 4px $shadow;
      border-radius: 0 0 0.325rem 0.325rem;
    }
    &__header {
      font-size: 24px;
      border-top: 0.25rem solid var(--ion-color-primary);
      border-bottom: none;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &__wrapper {
      box-shadow: 0 2px 4px $shadow;
      border-radius: 0.375em 0.375em 0 0;
      overflow: hidden;
    }
    &__divider {
      position: relative;
      height: $cutout-size;
      background-color: var(--ion-color-light);
      margin-left: calc($cutout-size / 2);
      margin-right: calc($cutout-size / 2);
      &::after {
        content: '';
        position: absolute;
        height: 50%;
        width: 100%;
        top: 0;
        left: 0px;
        border-bottom: 2px dashed $divider;
      }
    }
    &__notch {
      position: absolute;
      left: calc($cutout-size / 2) * -1;
      width: $cutout-size;
      height: $cutout-size;
      overflow: hidden;
      &::after {
        content: '';
        position: relative;
        display: block;
        width: calc($cutout-size * 2);
        height: calc($cutout-size * 2);
        right: 100%;
        top: -50%;
        border: calc($cutout-size / 2) solid var(--ion-color-light);
        border-radius: 50%;
        box-shadow: inset 0 2px 4px $shadow;
      }
      &--right {
        left: auto;
        right: calc($cutout-size / 2) * -1;
        &::after {
          right: 0;
        }
      }
      
    }
    &__body {
        // border-bottom: none;
        border-top: none;
      & > * + * {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $divider;
      }
    }
  }