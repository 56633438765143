.benefit-screen-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  .description {
    font-size: 12px;
    margin: 0px;
  }
}
