.not-found-page {
  .claro-club-content {
    .card-message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      text-align: center;
      width: 100%;
      gap: 20px;
      .logo-claro-club {
        width: 200px;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 10px;
        h1,
        p {
          margin: 0px;
        }
        h1 {
          font-size: 2rem;
          font-weight: 700;
          text-wrap: balance;
          margin-bottom: 5px;
        }
        p {
          text-wrap: balance;
          font-size: 1rem;
          font-weight: 400;
        }
        p + p {
          font-weight: 700;
          margin-top: 5px;
        }
      }
    }
  }
}
