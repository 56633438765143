.profit-modal {
  padding: 0px;
  --background: var(--ion-color-primary);
  color: #fff;

  &::part(content) {
    border-radius: 0px;
    max-width: 100%;
    min-height: 100%;
    --height: 100%;
  }
  @media (min-width: 768px) {
    border-radius: 8px;
    padding: 16px;

    &::part(content) {
      border-radius: 8px;
      max-width: 400px;
      min-height: 500px;
      --height: 500px;
    }
  }

  .content {
    gap: 35px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--ion-color-dark);
    margin: 0px 50px;

    .header {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;

      h3 {
        margin: 0px;
        font-size: 24px;
      }

      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .description {
        // margin: 5px 0px 30px 0px;
        font-size: 12px;
        color: #F2F2F2;
      }

      ion-icon {
        font-size: 60px;
        animation: pulse 2s infinite;
        margin-bottom: 20px;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .agreed-button {
        --box-shadow: none;
        height: 57px;
        text-transform: none;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        --background: #fff;
        color: var(--ion-color-primary);
      }
  
      a {
        text-decoration: none;
        height: 57px;
        color: #fff;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff; 
        width: 100%;
        display: flex;
        border-radius: 50px;
        font-size: 14px;
      }
    }
  }
}
