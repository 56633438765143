.request-partner-screen {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 40px;
    width: 100%;
    height: 100%;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      gap: 10px;
      ion-icon {
        font-size: 70px;
        margin-bottom: 20px;
      }
      text-align: center;
      h3 {
        font-size: 16px;
        margin: 0px;
      }
      p {
        font-size: 14px;
        margin: 0px;
      }
    }

    .actions-buttons {
        display: flex;
      margin-top: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
