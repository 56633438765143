#help_center_modal {
  .container-help-center {
    padding: 30px;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 0px 0px 10px 0px;
      text-align: center;
    }
  }
}
