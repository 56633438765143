.categories-benefits {
  list-style-type: none;
  margin: 0;
  margin-top: 30px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: #ddd;
      top: 35px;
      left: -4px;
      z-index: -1;
    }
    &:last-child:after {
      width: 0px;
      height: 100%;
      content: "";
      background-color: #ddd;
    }
    &:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      left: -12px;
      top: 35px;
      background: #bdbdbd;
    }

    &.active:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      left: -12px;
      top: 35px;
      background: red;
    }

    .item {
      margin-left: 15px;
      height: 83px;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      position: relative;

      &:hover {
        cursor: pointer;
        background: var(--ion-color-light-tint);
        border-radius: 20px;
        .content {
          border-bottom: none;
        }
      }

      &.active {
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 20px;
        background-color: var(--ion-color-primary);
        color: #fff;
        .content {
          border-bottom: none;
        }
      }
      .icon {
        background: var(--ion-color-medium);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon {
          font-size: 52px;
        }
      }
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e0e0e0;

        ion-icon {
          color: var(--ion-color-primary);
          font-size: 20px;
        }
        .text {
          display: flex;
          flex-direction: column;
          gap: 10px;
          h3,
          p {
            margin: 0px;
          }
          h3 {
            font-size: 14px;
            text-transform: capitalize;
          }
          p {
            font-size: 12px;
            
          }
        }
      }
    }
  }
}
