.other_method {
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 16px;
  height: 600px;
  width: 100%;
  max-width: 500px;
  text-align: center;

  .other_method_country {
    cursor: pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-medium);
    border-radius: 8px;
    margin-bottom: 32px;
    ion-icon {
      font-size: 32px;
      margin: auto 0px;
    }
    p {
      margin: 0 0 0 12px;
    }
  }

  form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: auto;
    flex-direction: column;
  }

  .call-icon {
    font-size: 82px;
    display: flex;
    margin: 48px 0px;
    width: 82px;
    height: 82px;
    display: flex;
    align-self: center;
  }
}
