#placeholder{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    ion-icon{
        font-size: 86px;
    }
    h3 {
        text-align: center;
        font-size: 18px;
        margin: 16px 0 8px 0;
        text-wrap: balance;
    }
    p{
        margin: 0px;
        text-align: center;
        font-size: 16px;
        margin: 8px 0;
        text-wrap: balance;
        max-width: 340px;
    }
}