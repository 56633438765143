.code_modal{
    .code_modal_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 500px;
        margin: auto;
        padding: 16px;
        h3{
            width: 100%;
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 32px;
            color: var(--ion-color-dark);
        }
        p{
            color: var(--ion-color-dark);
        }
        ion-button{
            width: 100%;
        }
    }

    form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: auto;
        flex-direction: column;
      }
}