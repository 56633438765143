#profile_header {
  width: 100%;
  ion-item {
    --inner-padding-bottom: 16px;
    --inner-padding-top: 16px;
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .profile_header_card {
    background: var(--ion-color-light);
    padding: 32px 16px;
    display: flex;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 8px;
    margin: 0 8px 8px 8px;
    flex-direction: column;
    gap: 20px;
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .icon-title {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon {
          display: flex;
          align-items: center;
          margin-left: auto;
          font-size: 16px;
          font-weight: 600;
          justify-content: center;
          background-color: #F2F2F2;
          border-radius: 50%;
          padding: 10px;
          ion-icon {
            font-size: 20px;
          }
        }
        .icon-medal {
            ion-icon {
              font-size: 40px;
            }
          }
      }
      .total {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: 16px;
        font-weight: 600;
      }
      .stage {
        padding: 8px;
        border-radius: 5px;
        background-color: var(--ion-color-primary);
        text-align: center;
        color: #fff;
        font-size: 10px;
      }
    }
    p {
      margin: 0;
    }
  }
}
