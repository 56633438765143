.confirmation_request_modal {
  padding: 0px;

  &::part(content) {
    max-width: 100%;
    min-height: 100%;
    --height: 100%;
  }
  @media (min-width: 768px) {
    padding: 16px;

    &::part(content) {
      max-width: 400px;
      min-height: 500px;
      --height: 500px;
    }
  }

  .content {
    gap: 35px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--ion-color-dark);
    margin: 0px 50px;

    .header {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 0px;
        font-size: 16px;
      }
      p {
        // margin: 5px 0px 30px 0px;
        font-size: 12px;
      }

      ion-icon {
        font-size: 60px;
        animation: pulse 2s infinite;
        margin-bottom: 20px;
      }
      .icon-error {
        color: var(--ion-color-primary);
      }
    }

    .agreed-button {
      --box-shadow: none;
      height: 40px;
      text-transform: none;
      width: 100%;
      font-size: 14px;
    }
  }
}
