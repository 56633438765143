.gift-screen {
  .gift-content {
    display: flex;
    flex-direction: column;
    margin: 40px 12px 12px 12px;
    gap: 30px;
    align-items: center;
    h3,
    p {
      margin: 0px;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
    }
  }

  .gift-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: linear-gradient(
      rgba(var(--ion-color-light-rgb), 0) 2.5%,
      rgba(var(--ion-color-light-rgb), 0.92) 55.35%
    );
    padding: 24px 16px 32px;

    ion-button {
      margin: auto;
      max-width: 400px;
      --box-shadow: none;
      text-transform: none;
      height: 56px;
    }
  }
}
