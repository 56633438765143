#store_item{
    display: flex;
    align-items: center;
    padding: 4px;
    margin: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 8px;
    cursor: pointer;
    background: var(--ion-color-light);
    width: 100%;
    transition: transform .2s;
    &:hover{
        transform: scale(1.02);
    }
    ion-img{
        height: 86px;
        width: 130px;
        margin-right: 12px;
        &::part(image){
            object-fit: cover;
            border-radius: 6px;

        }
    }
    .store_item_title{
        h2{
            margin: 0;
            font-size: 16px;
            font-weight: 600;
        }
        .store_item_subtitle{
            display: flex;
            align-items: center;
            margin-top: 8px;
            color: rgba(var(--ion-color-dark-rgb), 0.5);
            ion-icon{
                margin-right: 4px;
            }
        }
    }
    ion-icon.store_item_detail{
        margin-left: auto;
        margin-right: 8px;
    }
}