#store_list_item{
    padding: 8px 16px;
    h3{
        margin: 0;
        font-size: 16px;
    }
    p{
        font-size: 12px;
        margin: 4px 0 0 0;
        // color: rgba(var(--ion-color-dark-rgb), 0.5);
    }
    .store_list_item_buttons{
        display: flex;
        justify-content: space-around;
        ion-button{
            text-transform: none;
            color: rgba(var(--ion-color-dark-rgb), 0.5);
        }
    }
}