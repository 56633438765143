#coupon_header {
    position: sticky;
    background-color: transparent;
    box-shadow: none;
    ion-toolbar {
        --background: transparent;
        box-shadow: none;
        --border-style: none;
        --border-color: none;
    }
  
    ion-back-button{
        display: flex;
        justify-content: center;
        align-items: center;
        
        &::part(native){
            width: 40px;
            min-height: 40px;
            height: 40px !important;
        }
        --background: var(--ion-color-light);
    }
    ion-button{
        width: 40px;
        height: 40px;
    }
}