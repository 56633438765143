#store_item_skeleton{
    max-width: 500px;
    min-width: 320px;
    padding: 4px;
    margin: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 8px;
    background: var(--ion-color-light);
    display: flex;
    .store_item_skeleton_text{
        width: 100%;
        margin: 4px 4px;

        .store_item_skeleton_title{
            width: 50%;
            height: 30%;
        }
        .store_item_skeleton_subtitle{
            width: 80%;
            height: 40%;
        }
    }
    .store_item_skeleton_image{
        width: 90px;
        height: 90px;
        border-radius: 8px;
        display: flex;
        margin-left: auto;
    }
}