#category_item_skeleton{
    max-width: 500px;
    min-width: 320px;
    padding: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin: 12px 8px;
    border-radius: 8px;
    background: var(--ion-color-light);
    display: flex;
    .category_item_skeleton_text{
        width: 100%;

        .category_item_skeleton_title{
            width: 50%;
        }
        .category_item_skeleton_subtitle{
            width: 80%;
        }
    }
    .category_item_skeleton_image{
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        margin-left: auto;
    }
}