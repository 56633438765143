.coupon_header_info {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  width: 100%;
  word-break: break-all;
  gap: 15px;
  h3 {
    font-weight: 700;
    margin: 0px;
    font-size: 28px;
  }
  ion-button {
    --box-shadow: none;
    height: 57px;
    text-transform: none;
    width: fit-content;
    min-width: fit-content;
    font-size: 14px;
   }
}
