.exchanges-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .redeemed {
      font-size: 12px;
      color: var(--ion-color-primary);
      margin: 0px;
    }
    h2 {
      font-size: 14px;
      margin: 0px;
    }
  }

  .exchanges {
    list-style: none;
    padding: 0px;
    margin: 0px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0;
      min-height: 65px;
      margin: 10px 0px;
      padding: 10px;

      .title {
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: center;
        gap: 10px;
        ion-icon {
          font-size: 15px;
        }
        p {
          font-size: 12px;
          margin: 0px;
          text-wrap: balance;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
      .redeem-button {
        --box-shadow: none;
        height: 32px;
        text-transform: none;
        min-width: 138px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}
