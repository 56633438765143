.terms-conditions-section {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2{ 
    font-size: 14px;
    margin: 0px;
  }

  p {
    font-size: 12px;
    margin: 0px;
  }

 
}
