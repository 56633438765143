#branch_item {
  display: grid;
  grid-template-columns: 1fr 148px;
  align-items: center;
  padding: 8px 16px;
  h3 {
    font-size: 16px;
    margin: 0;
  }
}
