.benefits-screen-content {
  padding: 20px;
  width: 100%;
  .header {
    h2 {
      font-size: 16px;
      margin: 0px;
    }
    p {
      font-size: 12px;
      margin: 0px;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
