ion-tab-bar{
    display: flex;
    transition: .1s ease-in-out;
    &.hide{
    display: none;
    }
    ion-tab-button{
        --color: var(--ion-color-dark);
        --padding-end: 8px;
        --padding-start: 8px;
        ion-icon{
            font-size: 18px;
        }
        ion-label{
            font-size: 12px;
        }
    }
}