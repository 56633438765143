.version_modal {
  padding: 16px;

  &::part(content) {
    border-radius: 8px;
    max-width: 400px;
    min-height: 450px;
    --height: 450px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    padding: 32px;
    flex-direction: column;
    height: 100%;
    width: 100%;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      text-align: center;
      text-wrap: balance;
    }
    p {
      font-size: 14px;
      margin-bottom: 16px;
      text-align: center;
      text-wrap: pretty;
    }
  }
}
