.splash-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #fff;

    img {
        width: 100%;
        max-width: 200px;
    }
}