.categories-screen-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .categories-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .categories {
    width: 100%;
    max-width: 900px;
    align-items: center;
    justify-content: center;
    place-items: center;
    .category-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 30px;
      justify-content: center;
      width: 100%;
      align-items: center;
    }
  }
}
