ion-header#claro_club_header {
    &.transparent{
        ion-toolbar{
        --border-color: transparent;
        }
    }
    ion-toolbar{
        --padding-end: 8px;
        .claro_club_header_country{
            --border-radius: 8px;
            margin-left: 8px;
        }
        .close-button {
            width: 40px;
            height: 40px;
        }
    }
}