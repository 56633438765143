.accordion-group-record {
  .accordion-record {
    ion-item {
      margin: 0px;
      padding: 0px;
      --padding-start: 0px;
      --inner-padding-end: 0px;
      --background-hover: none;
      pointer-events: none;
    }
    h3 {
      font-size: 14px;
      font-weight: 700;
    }
    .ion-accordion-toggle-icon {
      font-size: 20px;
      color: var(--ion-color-primary);
    }

    .records {
      list-style: none;
      padding: 0px;
      margin: 0px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
        height: 65px;
        margin: 10px 0px;
        padding: 10px;

        .title-content {
          width: 100%;
          display: grid;
          grid-template-columns: 15px 1fr;
          align-items: center;
          gap: 20px;
          ion-icon {
            font-size: 15px;
          }
          p {
            margin: 0px;
          }
          .title {
            gap: 5px;
            display: flex;
            flex-direction: column;
            .description {
              font-size: 12px;
              text-wrap: balance;
            }
            .date {
              font-size: 10px;
              color: rgba(var(--ion-color-dark-rgb), 0.6);
            }
          }
        }
        .redeem-button {
          --box-shadow: none;
          height: 32px;
          text-transform: none;
          width: fit-content;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }

  .placeholder {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    ion-icon {
      font-size: 80px;
    }
    img {
      width: 90px;
      height: 90px;
    }
    p {
      text-align: center;
      font-size: 12px;
      margin: 0px;
    }
  }
}
