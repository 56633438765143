#country_screen{
    .country_screen_header{
        text-align: center;
        width: 100%;
        ion-img{
            margin: auto;
        }
    }
    ion-button{
        width: 100%;
    }
}