.coupon_redeem_modal {
  ion-content {
    .coupon_redeem_modal_wrapper {
      height: 100%;
      display: flex;
      // height: calc(100% - 48px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;

      .coupon-card {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
      }
      h2 {
        // margin-top: 20px;
        font-size: 30px;
        margin-bottom: 0;
        font-weight: 600;
      }
      p {
        margin: 0;
        margin-bottom: 26px;
        text-align: center;
      }
      h3 {
        margin-top: 48px;
      }
    }
  }
}
