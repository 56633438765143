.gift_modal {
  padding: 0px;

  &::part(content) {
    max-width: 100%;
    min-height: 100%;
    --height: 100%;
  }
  @media (min-width: 768px) {
    padding: 16px;

    &::part(content) {
      max-width: 100%;
      min-height: 100%;
      --height: 100%;
    }
  }

  .content {
    gap: 35px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--ion-color-dark);
    margin: 0px 12px;
    h3,
    p {
      margin: 0px;
    }
    p {
      font-size: 14px;
    }

    .ticket-content {
      width: 100%;
      position: relative;
      .ticket-info {
        padding: 20px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        img {
          width: 200px;
          object-fit: cover;
          object-position: center;
          height: 200px;
        }
        ion-icon {
          font-size: 80px;
        }

        h3 {
          font-size: 20px;
          font-weight: 700;
        }

      }
    }

    .share {
      text-align: center;
      display: flex;
      margin: 0px 10px;
      flex-direction: column;
      gap: 40px;
      .action {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .divider {
          background-color: #bdbdbd;
          height: 100%;
          width: 1px;
        }
        .button {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: center;
          align-items: center;
          button {
            background-color: #f2f2f2;
            text-transform: none;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            font-size: 20px;

            ion-icon {
              color: var(--ion-color-primary);
              font-size: 20px;
            }
          }
        }
      }
    }

    .icon-gift {
      font-size: 60px;
      animation: pulse 2s infinite;
      margin-bottom: 20px;
    }

    .share-button {
      --box-shadow: none;
      height: 57px;
      text-transform: none;
      width: 100%;
      font-size: 14px;
    }
  }
}
