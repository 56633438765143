.banner {
  &::before {
    height: 200px;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(51, 51, 51, 0.7);
    color: #fff;
    z-index: 0;
  }
  position: rleative;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 24px;
    color: #fff;
    z-index: 1;
  }
  .know-benefits-button {
    --box-shadow: none;
    height: 40px;
    text-transform: none;
    width: fit-content;
    font-size: 14px;
  }
}
