.claro-segments {
  background: var(--ion-segment-background-color);
  display: flex;
  justify-items: start;
  align-items: start;
  width: 100%;
  border-radius: 100px;
  flex-direction: row;
  position: relative;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    cursor: pointer;
    .segment {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      position: absolute;
    }

    .element {
      height: 48px;
      border-radius: 100px;
      position: absolute;
      background-color: var(--ion-segment-background-button-color);
      z-index: 0;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      width: fit-content;
      min-width: fit-content;
      transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .segment-button {
        font-weight: 500;
        width: 100%;
        height: 100%;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        margin: 0px;
        &.active {
            color: #ffffff;
        }
    }
  }
}