#category_item{
    display: flex;
    max-width: 500px;
    min-width: 240px;
    padding: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin: 12px 8px;
    border-radius: 8px;
    cursor: pointer;
    background: var(--ion-color-light);
    transition: transform .2s;
    &:hover{
        transform: scale(1.01);
    }
    .category_item_title{
        margin: auto 0;
        
        h3{
            margin: 0;
            font-size: 16px;
        }
        &_icon{
            display: flex;
            align-items: center;
            margin-top: 8px;
            color: rgba(var(--ion-color-dark-rgb), 0.5);
            p{
                margin: 0 0 0 8px;
                font-size: 14px;
            }
        }
    }
    ion-img, img, .claro_club_coupon_image{
        margin-left: auto;
        width: 146px;
        height: 100%;
    }

    @media (max-width: 768px){
        max-width: 100%;
    }
}