.claro-club-content {
  --padding-top: 8px;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-bottom: 8px;
  padding: 8px 8px 8px 8px;
  width: 100%;
}

.claro-club-button {
  --box-shadow: none;
  height: 56px;
  text-transform: none;
  width: 100%;
}

.claro-club-full-modal {
  &::part(content) {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}

form {
  width: 100%;
}
.claro-club-input {
  --padding-start: 0;
  --inner-padding-end: 0;
  width: 100%;
  margin: 0 0 8px 0;
  font-size: 14px;
  ion-label {
    font-size: 16px !important;
    margin-bottom: 12px !important;
  }
  // &:hover{
  //     border: 1px solid var(--ion-color-secondary-shade);
  // }
  &.item-has-focus {
    ion-label {
      color: var(--ion-color-dark) !important;
      font-weight: 500;
    }
    ion-input,
    ion-textarea {
      --background: var(--ion-color-medium);
    }
  }

  ion-input,
  ion-textarea {
    margin-top: 0px;
    --placeholder-color: var(--ion-color-dark);
    --padding-start: 8px !important;
    --padding-end: 8px;
    --background: var(--ion-color-medium);
    min-height: 52px;
    border-radius: 8px;
  }
}
.claro-club-input-outline {
  --padding-start: 0;
  --inner-padding-end: 0;
  background: transparent;
  --background: transparent;
  width: 100%;
  margin: 0 0 8px 0;
  font-size: 12px;
  ion-label {
    font-size: 12px !important;
    margin-bottom: 12px !important;
  }

  &:focus-within {
    ion-input,
    ion-textarea {
      border: 1px solid var(--ion-color-primary);
    }
  }
  &.item-has-focus {
    ion-label {
      color: var(--ion-color-primary) !important;
      font-weight: 500;
    }
    ion-input,
    ion-textarea {
      --background: var(--ion-color-medium);
    }
  }

  ion-input,
  ion-textarea {
    margin-top: 0px;
    --placeholder-color: var(--ion-color-dark);
    --padding-start: 8px !important;
    --padding-end: 8px;
    --background: var(--ion-color-medium);
    min-height: 52px;
    border-radius: 8px;
  }
  .input-wrapper,
  .textarea-wrapper {
    border-radius: 8px;
  }
}

button {
  &.form-button {
    width: 100%;
    height: 56px;
    text-transform: none;
    border-radius: 46px;
    padding: 8px 24px 8px 24px;
    font-weight: 500;
    font-size: 14px;
    &.primary {
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      &:hover {
        background: rgba(var(--ion-color-primary-rgb), 0.9);
      }
    }
    &:disabled,
    &[disabled] {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes keyframes-star {
  0% {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }

  50% {
    transform: rotate(10deg) scale(1.3);
  }
}


ion-content {
  --keyboard-offset: 0px !important;
}