.benefit_modal {
  padding: 16px;

  &::part(content) {
    border-radius: 8px;
    max-width: 400px;
    min-height: 500px;
    max-height: 650px;
    --height: 100%;
  }

  .benefit-content {
    display: grid;
    width: 100%;
    height: 100%;
    &.has-pagination {
      grid-template-rows: 1fr 50px;
    }
    .slides-benefits {
      height: 100%;
      width: 100%;

      .benefit-card {
        padding: 0px;
        display: grid;
        grid-template-rows: auto 1fr;
        overflow: auto;
        // flex-direction: column;
        gap: 10px;
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: center;
        color: var(--ion-color-dark);
        border-radius: 10px;
        box-shadow: none;
        img {
          width: 100%;
          object-fit: cover;
          min-height: 160px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          max-height: 320px;

          @media (max-width: 360px) {
            max-height: 200px;
          }
        }
        ion-icon {
          font-size: 60px;
          animation: pulse 2s infinite;
          margin-bottom: 20px;
        }
        .content {
          margin: 0px 50px;
          gap: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          h3 {
            margin: 0px;
            font-size: 16px;
          }
          p {
            margin: 5px 0px 30px 0px;
            font-size: 14px;
          }

          a {
            background-color: var(--ion-color-primary);
            text-decoration: none;
            height: 57px;
            color: #fff;
            align-items: center;
            justify-content: center;
            // border: 1px solid #fff;
            width: 100%;
            display: flex;
            border-radius: 50px;
            font-size: 14px;
          }
        }
      }
    }
    .swiper-pagination {
      position: static !important;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 50px;
    }

    .swiper-pagination-bullets {
      min-height: 30px;
      .swiper-pagination-bullet-active {
        background: var(--ion-color-primary) !important;
      }
      .swiper-pagination-bullet {
        width: 32px;
        height: 6px;
        border-radius: 100px;
        background: var(--ion-color-dark);
      }
    }
  }
  .close-button {
    position: absolute;
    top: 0px;
    right: 8px;
    z-index: 10;
    width: 32px;
    height: 32px;
    margin-left: auto;
    --padding-start: 4px;
    --padding-end: 4px;
    --box-shadow: none;
    ion-icon {
      font-size: 20px;
      color: var(--ion-color-dark);
    }
  }
}
