#coupon_skeleton{
    border-radius: 8px;
    margin: 8px;
    background: var(--ion-color-light);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    .coupon_skeleton_image{
        min-height: 160px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .coupon_skeleton_content{
        padding: 12px;
        .coupon_skeleton_text{
            margin: 42px 0 0 0;
        }
    }
}