#contact_us_screen{
    section{
        padding: 16px;
        width: 100%;
        h3{
            margin: 0 0 12px 0;
            font-weight: 500;
            font-size: 16px;
        }
        p{
            margin: 0;
            font-size: 14px;

        }
    }   
}