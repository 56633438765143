.claro_club_sidemenu {
  &_logo {
    --padding-top: 24px;
    --padding-bottom: 24px;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .version {
      color: var(--ion-color-primary);
      text-align: center;
      font-size: 15px;
      margin: 0;
      padding-bottom: 30px;
    }
  }
}
